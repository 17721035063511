export const colors = {
    "logo-color": "#FFC700",
    "border-color": "#2F3640",
    chartBackgroundColors: [
        "rgba(231, 76, 60, 0.7)",   // Kırmızı
        "rgba(52, 152, 219, 0.7)",  // Mavi
        "rgba(46, 204, 113, 0.7)",  // Yeşil
        "rgba(241, 196, 15, 0.7)",  // Sarı
        "rgba(155, 89, 182, 0.7)",  // Mor
        "rgba(230, 126, 34, 0.7)",  // Turuncu
        "rgba(149, 165, 166, 0.7)", // Gri
        "rgba(52, 73, 94, 0.7)",    // Koyu Gri
        "rgba(128, 0, 128, 0.7)",   // Menekşe
        "rgba(0, 128, 128, 0.7)",   // Deniz Yeşili
        "rgba(255, 99, 132, 0.7)",  // Pembe
        "rgba(75, 192, 192, 0.7)"   // Cam Göbeği
      ],
      
    chartBackgroundColors2: [
        "rgba(255, 183, 77, 0.5)",
        "rgba(255, 167, 38, 0.5)",
        "rgba(255, 152, 0, 0.5)",
        "rgba(251, 140, 0, 0.5)",
        "rgba(245, 124, 0, 0.5)",
        "rgba(239, 108, 0, 0.5)",
        "rgba(230, 81, 0, 0.5)",
        "rgba(255, 209, 128, 0.5)",
        "rgba(255, 171, 64, 0.5)",
        "rgba(255, 145, 0, 0.5)",
        "rgba(255, 109, 0, 0.5)",
        "rgba(255, 87, 34, 0.5)"
      ],
    "chart": {
        "dark": {
            "grid-color":"#3D3D3D",
            "font-color":"#D3D3D3"
        },
        "light":{
            "grid-color":"#E1E1E1",
            "font-color":"#424242"
        }
    }
}
