import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { BsGeoAlt } from 'react-icons/bs';

const WorkplaceSelector = ({role}) => {
    const [workplaces, setWorkplaces] = useState([]);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { token, setCompany,company } = useAuth();
    const dropdownRef = useRef(null);

    // API çağrısı ve işyerlerini yükleme
    useEffect(() => {
        if (!token) return; // Eğer token yoksa API'yi çağırma
        fetch('https://dashboard.thermosoft.com.tr/api/company/companies', {
            method: 'GET',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                setWorkplaces(data);
                const selected = data.find(item => item.id === company);
    
                if (selected) {
                    setSelectedWorkplace(selected);
                } else if (data.length > 0) {
                    setSelectedWorkplace(data[0]); // Eğer eşleşen bir company_id yoksa ilk işyeri seçilir
                }
            })
            .catch(error => {
                console.error("Error fetching workplaces", error);
            });
    }, [token]);
    

    // Seçilen işyerini güncelle
    const handleSelect = (workplace) => {
        setSelectedWorkplace(workplace);
        setCompany(workplace.id);
        setIsDropdownOpen(false); // Dropdown'u kapat
    };

    // Dropdown açma/kapama
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Dış tıklama ile dropdown kapama
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Dropdown'u kapat
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div onClick={toggleDropdown} className="flex flex-row w-5/6 h-10 justify-center xl:justify-normal items-center md:gap-2 md:px-2 rounded-sm cursor-pointer text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color" ref={dropdownRef}>
            {/* Seçilen işyeri logo veya ismi */}
            <div className="overflow-hidden flex items-center justify-center" >
                {selectedWorkplace && (
                    <div className="flex flex-row justify-center xl:justify-normal items-center md:gap-2 rounded-sm cursor-pointer">
                        <BsGeoAlt className='hidden sm:block' size={22}/>
                        <p className=" hidden xl:block sm:text-xs lg:text-sm 4xl:text-2xl font-semibold">{selectedWorkplace.company_name.toUpperCase()}</p>
                    </div>
                ) }
            </div>

            {/* Dropdown menü 
            
            w-14 xl:w-44 3xl:w-60 5xl:w-80
            */}
            {(isDropdownOpen && role ==="SUPERADMIN") && (
                <div className="fixed w-[150px] left-14 xl:left-44 3xl:left-60 5xl:left-80 mt-2 bg-sideheadbar-color border-border-color border shadow-lg rounded-lg z-50">
                    {workplaces.map(workplace => (
                        <div
                            key={workplace.id}
                            className={`flex items-center px-5 py-2 cursor-pointer border-b border-border-color ${workplace.company_name === selectedWorkplace.company_name
                                ? "bg-logo-color text-black"
                                : "text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color"}`}
                            onClick={() => handleSelect(workplace)}
                        >
                            <span className="text-text-color">{workplace.company_name.toUpperCase()}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WorkplaceSelector;
