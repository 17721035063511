import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chartValues: [],
    tableValues: [],
    date: null,
    chartType: "LineChart",
    interval: null,
    period: null
}

export const selectedOptionSlice = createSlice({
    name: "selectedOptions",
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setChartValues: (state, action) => {
            state.chartValues = action.payload;
        },
        setChartType: (state, action) => {
            state.chartType = action.payload;
        },
        setInterval: (state, action) => {
            state.interval = action.payload
        },
        setTableValues: (state, action) => {
            state.tableValues = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
    }
})

export const { setDate, setChartValues, setChartType, setInterval, setTableValues, setPeriod } = selectedOptionSlice.actions

export default selectedOptionSlice.reducer;