import React, { useEffect, useState } from "react";
import PredictionTable from "../components/tables/PredictionTable";
import { useAuth } from "../providers/AuthProvider";


const Prediction = () => {
  const [columnNames, setColumnNames] = useState([]);
  const [predictedData, setPredictedData] = useState([]);
  const { token } = useAuth();



  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.thermosoft.com.tr/api/prediction/nextfivedays", {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data && typeof data === "object" && !Array.isArray(data)) {
          const dayNames = Object.keys(data);
          setColumnNames(dayNames);
          processRawData(data);

        } else {
          console.error(
            "Received data is not in the expected format or is null:",
            data
          );
        }
      } catch (error) {

        console.error("Veri yüklenirken bir hata oluştu:", error);
      }
    };

    fetchData();
  }, [])

  const processRawData = (incomingData) => {


    const hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

    const processedData = hours.map((hour) => {
      const row = { hour };

      for (const date of Object.keys(incomingData)) {

        row[date] = incomingData[date][hour];
      }

      return row;
    });

    setPredictedData(processedData);
  };

  return (
    <div className="flex flex-1 w-full h-full overflow-hidden">
      {predictedData.length > 0 && (
        <PredictionTable data={predictedData} columnNames={columnNames} />
      )}
    </div>
  );
};

export default Prediction;
