import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import { setTableValues } from "../../redux/selectedOptionsSlice";
import { useTranslation } from 'react-i18next';
import { colors } from "../../consts/colors";
import "./TableMultiSelectBox.css";
import { useAuth } from "../../providers/AuthProvider";

const MultiSelectBox = ({ selectedColors ,theme}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const [orderedOptions, setOrderedOptions] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);
  const { token } = useAuth();

  const textcolor =
  theme === "dark"
    ? colors.chart.dark["font-color"]
    : colors.chart.light["font-color"];

  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await fetch("https://dashboard.thermosoft.com.tr/api/metadata/columns", {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)
        setFieldNames(data);
      } catch (error) {
        console.error("Error fetching columns:", error);
      }
    };

    fetchColumns();
  }, [token]);

  const options = Object.entries(fieldNames).flatMap(([tableName, columns], tableIndex) =>
    columns.map((columnName, columnIndex) => ({
      value: columnName,
      label: columnName, 
      table:tableName
    }))
  );

  
  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      const val = (i) => values[i].props.children;
      const { length } = values;

      switch (length) {
        case 1:
          values = `${val(0)} selected`;
          break;
        case 2:
          values = `${val(0)} and ${val(1)} selected`;
          break;
        default:
          const otherCount = length - 2;
          values = `${val(0)}, ${val(1)} and ${otherCount}`;
          break;
      }
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  useEffect(() => {
    dispatch(setTableValues(selectedOptions));
  }, [selectedOptions, dispatch]);

  const reorderOptions = (selected) => {
    const selectedValues = selected.map(option => option.value);
    const ordered = [
      ...selected,
      ...orderedOptions.filter(option => !selectedValues.includes(option.value))
    ];
    setOrderedOptions(ordered);
  };

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []);
    reorderOptions(selected || []);
  };

  return (
    <div className="w-full !h-7 md:!h-10 3xl:!h-16 4xl:!h-20 bg-background-color z-30">
      <Select
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        className="react-select-container"
        classNamePrefix="react-select"
        isSearchable={true}
        closeMenuOnSelect={false}
        isMulti
        placeholder={t('chart.select')}
        options={options}
        onChange={handleSelectChange}
        isClearable={true}
        components={{ ValueContainer }}
        styles={{
          control: (provided) => ({
            ...provided,
            minWidth: 150,
            boxShadow: "none",
            zIndex: 100,
            color: textcolor,
          }),
          menu: (provided) => ({
            minWidth: 150,
            borderRadius: 1,
            zIndex: 1,
          }),
          option: (base) => ({
            ...base,
            borderBottom: `0.2px groove gray`,
            height: '100%',
          }),
          input: (base) => ({
            ...base,
            color: textcolor,
          }),
          menuList: (base) => ({
            ...base,
            zIndex: 10,
            "::-webkit-scrollbar": {
              width: "7px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#1F1F1F",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#9A9A9A",
            },
          }),
          clearIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            borderColor: colors["logo-color"],
            color: colors["logo-color"],
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            color: colors["logo-color"],
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: colors["logo-color"],
          }),
        }}
      />
    </div>
  );
};

export default MultiSelectBox;
