import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Chart, Pie } from "react-chartjs-2";
import { colors } from "../../consts/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ title, labels, data, backgroundColors, theme }) => {
  const color =
    theme === "dark"
      ? colors.chart.dark["font-color"]
      : colors.chart.light["font-color"];

  const borderColor = theme === "dark" ? "#1F1F1F" : "white";

  return (
    <Pie
      className="!w-full !h-full"
      plugins={[ChartDataLabels]}
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderColor: borderColor,
            borderWidth: 1,
            hoverOffset: 10,
          },
        ],
      }}
      options={{
        aspectRatio: 2,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              boxHeight: 6,
              boxWidth: 6,
            },
          },
          title: {
            text: title,
            display: true,
            color: color,
          },
          datalabels: {
            formatter: (value, context) => {
              const percentage = (
                (value / context.dataset.data.reduce((a, b) => a + b, 0)) *
                100
              ).toFixed(0);
              // Değerin yanına 'KW' ekliyoruz
              return value + " KW";
            },
            textAlign: "center",
            anchor: "end",
            labels: {
              index: {
                align: "end",
                anchor: "center",
                font: {
                  weight: 600,
                },
                color: color,
                formatter: function (value, ctx) {
                  return value + " KW"; // Burada da 'KW' ekleniyor
                },
              },
            },
          },
        },
        color: color,
      }}
    />
  );
};

export default PieChart;
