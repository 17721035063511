import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import { setTableValues } from "../../redux/selectedOptionsSlice";
import { useTranslation } from "react-i18next";
import { colors } from "../../consts/colors";
import "./TableMultiSelectBox.css";
import { useAuth } from "../../providers/AuthProvider";

const DashboardSelectBox = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const [fieldNames, setFieldNames] = useState([]);
  const { token } = useAuth();

  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await fetch("https://dashboard.thermosoft.com.tr/api/metadata/columns", {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);

        const options = [];
        Object.entries(data).forEach(([table, columns]) => {
          columns.forEach((column) => {
            const formattedColumn = column.endsWith("pv_out")
            ? column.slice(0, -7).trim()
            : column;

          options.push({
            value: { tableName: table, columnName: column }, // Orijinal değer
            label: `${formattedColumn}`.split("_").map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1)
            ).join(" "),
            color: "rgba(255, 99, 71, 1)",
          });
          });
        });
        setFieldNames(options);
      } catch (error) {
        console.error("Error fetching columns:", error);
      }
    };

    fetchColumns();
  }, [token]);

  useEffect(() => {
    // Dispatch the selected values (with table and column names) to Redux
    dispatch(setTableValues(selectedOptions.map(option => option.value)));
  }, [selectedOptions, dispatch]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      const val = (i) => values[i].props.children;
      const { length } = values;

      switch (length) {
        case 1:
          values = `${val(0)} selected`;
          break;
        case 2:
          values = `${val(0)} and ${val(1)} selected`;
          break;
        default:
          const otherCount = length - 2;
          values = `${val(0)}, ${val(1)} and ${otherCount}`;
          break;
      }
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };
  
  return (
    <div className="w-full !h-7 md:!h-10 text-sm  4xl:!h-20 bg-background-color z-30">
      <Select
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        className="react-select-container"
        classNamePrefix="react-select"
        isSearchable={true}
        closeMenuOnSelect={false}
        isMulti
        placeholder={t("chart.select")}
        options={fieldNames}
        onChange={handleSelectChange}
        components={{ ValueContainer }}
        isClearable={true}
        styles={{
          control: (provided) => ({
            ...provided,
            minWidth: 150,
            boxShadow: "none",
            zIndex: 100,
          }),
          menu: (provided) => ({
            minWidth: 150,
            borderRadius: 1,
            zIndex: 1,
          }),
          option: (base) => ({
            ...base,
            borderBottom: "0.2px groove gray",
            height: "100%",
          }),
          input: (base) => ({
            ...base,
            color: "white",
          }),
          menuList: (base) => ({
            ...base,
            zIndex: 10,
            "::-webkit-scrollbar": {
              width: "7px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#1F1F1F",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#9A9A9A",
            },
          }),
          clearIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            borderColor: colors["logo-color"],
            color: colors["logo-color"],
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            color: colors["logo-color"],
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: colors["logo-color"],
          }),
        }}
      />
    </div>
  );
};

export default DashboardSelectBox;
