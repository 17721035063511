import React, { useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { BsThreeDotsVertical } from "react-icons/bs";
import { colors } from "../../consts/colors";
import { useEffect } from "react";

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const ScatterChartDash = ({ theme, data, handleDelete, chartId }) => {
  const color =
    theme === "dark"
      ? colors.chart.dark["font-color"]
      : colors.chart.light["font-color"];
  const gridColor =
    theme === "dark"
      ? colors.chart.dark["grid-color"]
      : colors.chart.light["grid-color"];

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleMenuToggle = () => setMenuOpen((prev) => !prev);

  const formatDate = (date, interval) => {
    const options =
      interval === "hour"
        ? { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }
        : { day: "2-digit", month: "2-digit", year: "numeric" };

    return new Date(date).toLocaleString("tr-TR", options);
  };

  return (
    <div style={{ width: "100%", height: "100%" }} className="dash-scatterchart">
      <div className="relative flex items-center justify-between pt-1 px-2">
        {data.chartTitle && (
          <div className="chart-title text-center flex-1 text-lg font-semibold mb-3">
            {data.chartTitle}
          </div>
        )}
        {/* Üç nokta simgesi */}
        <button
          className="cursor-pointer text-text-color"
          onClick={handleMenuToggle}
         
        >
          <BsThreeDotsVertical />
        </button>

        {/* Pop-up menü */}
        {menuOpen && (
          <div
          ref={menuRef}
            className="absolute right-5 top-20 transform -translate-y-1/2 w-32 border border-border-color bg-sideheadbar-color rounded-md shadow-lg z-100"
          >
            <ul className="p-2">
              <li
                className="p-2 hover:bg-border-color cursor-pointer"
                onClick={() => {
                  handleDelete(chartId); 
                  setMenuOpen(false); 
                }}
              >
                Sil
              </li>
              <li
                className="p-2 hover:bg-border-color cursor-pointer"
                onClick={() => console.log("Düzenle")}
              >
                Düzenle
              </li>
            </ul>
          </div>
        )}
      </div>
      <Scatter
        className="text-text-color"
        options={{
          layout: {
            padding: {
              bottom: 40,
              top: -43,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: data.chartTitle,
              color: color,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const point = context.raw; // Veri noktasını al
                  const date = formatDate(point.x, data.interval); // Zamanı formatla
                  return `Zaman: ${date}, Değer: ${point.y}`;
                },
              },
            },
          },
          scales: {
            x: {
              type: "linear", // Scatter points için
              position: "bottom",
              grid: {
                color: gridColor,
              },
              ticks: {
                color: color,
                callback: function (value) {
                  return formatDate(value, data.interval); // Eksen tarih formatlama
                },
              },
              border: {
                color: color,
              },
            },
            y: {
              ticks: {
                color: color,
              },
              grid: {
                color: gridColor,
              },
              border: {
                color: color,
              },
            },
          },
        }}
        data={{
          datasets: data.datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.points, // Scatter points [{x: value, y: value}]
            backgroundColor: dataset.backgroundColor || colors.chart.scatter.default,
            borderColor: dataset.borderColor || colors.chart.scatter.border,
          })),
        }}
      />
    </div>
  );
};

export default ScatterChartDash;
