import React, { useState, useRef } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BsThreeDotsVertical } from "react-icons/bs";
import { colors } from "../../consts/colors";
import { useEffect } from "react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartDash = ({ theme, data, handleDelete, chartId }) => {
    const color =
        theme === "dark"
            ? colors.chart.dark["font-color"]
            : colors.chart.light["font-color"];
    const gridColor =
        theme === "dark"
            ? colors.chart.dark["grid-color"]
            : colors.chart.light["grid-color"];

    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setMenuOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    const handleMenuToggle = () => setMenuOpen((prev) => !prev);

    // Tarih formatını belirleyen işlev
    const formatDate = (date, interval) => {
        const options =
            interval === "hour"
                ? { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }
                : { day: "2-digit", month: "2-digit", year: "numeric" };

        return new Date(date).toLocaleString("tr-TR", options);
    };

    const labels =
        data?.datasets?.[0]?.data?.map((entry) =>
            formatDate(entry.x, data.interval)
        ) || [];

    const datasets =
        data?.datasets?.map((dataset, index) => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor || `rgba(75, ${index * 50}, 192, 0.6)`,
            borderColor: dataset.borderColor || `rgba(75, ${index * 50}, 192, 1)`,
            borderWidth: 1,
        })) || [];

    return (
        <div style={{ width: "100%", height: "100%" }} className="dash-barchart">
            <div className="relative flex items-center justify-between pt-1 px-2">
                {data.chartTitle && (
                    <div className="chart-title text-center flex-1 text-lg font-semibold mb-3">
                        {data.chartTitle}
                    </div>
                )}
                {/* Üç nokta simgesi */}
                <button
                    className="cursor-pointer text-text-color"
                    onClick={handleMenuToggle}

                >
                    <BsThreeDotsVertical />
                </button>

                {/* Pop-up menü */}
                {menuOpen && (
                    <div
                        ref={menuRef}
                        className="absolute right-5 top-20 transform -translate-y-1/2 w-32 border border-border-color bg-sideheadbar-color rounded-md shadow-lg z-100"
                    >
                        <ul className="p-2">
                            <li
                                className="p-2 hover:bg-border-color cursor-pointer"
                                onClick={() => {
                                    handleDelete(chartId); // chartId ile handleDelete çağrılır
                                    setMenuOpen(false); // Menü kapatılır
                                }}
                            >
                                Sil
                            </li>
                            <li
                                className="p-2 hover:bg-border-color cursor-pointer"
                                onClick={() => console.log("Düzenle")}
                            >
                                Düzenle
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <Bar
                className="text-text-color"
                options={{
                    layout: {
                        padding: {
                            bottom: 40,
                            top: -43,
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: data.chartTitle,
                            color: color,
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                color: gridColor,
                            },
                            ticks: {
                                color: color,
                            },
                            border: {
                                color: color,
                            },
                        },
                        y: {
                            ticks: {
                                color: color,
                            },
                            grid: {
                                color: gridColor,
                            },
                            border: {
                                color: color,
                            },
                        },
                    },
                }}
                data={{
                    labels: labels,
                    datasets: datasets,
                }}
            />
        </div>
    );
};

export default BarChartDash;
