import React, { useCallback, useEffect, useRef, useState } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { IoIosClose } from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import PredictionTable from "../components/tables/PredictionTable";
import NotificationTable from "../components/tables/NotificationTable";
import { useAuth } from "../providers/AuthProvider";
import photoMap from "../utils/santrals";
import positions from "../utils/positions";
import Draggable from "react-draggable";
import boppositions from "../utils/boppositions";



const Bop = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [notificationModal, setNotificationModal] = useState(false);

  const [data, setData] = useState({});
  const [modalTitle, setModalTitle] = useState("")
  const [boxData, setBoxData] = useState({});
  const { token, company } = useAuth();
  const [calculatedValues, setCalculatedValues] = useState({})


  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }

    const rootElement = document.getElementById('wrapper');
    rootElement.addEventListener('contextmenu', handleContextMenu);


    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);



  useEffect(() => {
    window.addEventListener("theme", () => {
      setTheme(localStorage.getItem("theme"));
    });
  }, []);

  const divRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: div } = divRef;

    if (div) {
      const value = make3dTransformValue({ x, y, scale });

      div.style.setProperty("transform", value);
    }
  }, []);

  const onClick = (value, modalTitle) => {

    const fetchData = async () => {
      try {
        setData(null); // Yeni veri yüklemesi başlamadan önce mevcut veriyi sıfırla
        const response = await fetch(
          `https://dashboard.thermosoft.com.tr/api/booster-pumps/latest?pumpName=${value}`,
          {
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setData(json);
        console.log(json)
      } catch (error) {
        console.error("Veri yüklenirken bir hata oluştu:", error);
        setData(null); // Hata durumunda data'yı null yap
      }
    };


    fetchData();
    setModalTitle(modalTitle);
    setIsOpen(true);
  };

  const renderDataModal = () => {
    if (!data || Object.keys(data).length === 0) {
      return <div className="p-4 text-center text-gray-500">Veri mevcut değil</div>;
    }

    return Object.keys(data).map((key, index) => {
      const entry = data[key];

      const value = entry?.value ?? entry;
      const unit = entry?.unit || "";

      const displayValue = typeof value === "number"
        ? parseFloat(value).toFixed(2)
        : value;

      return (
        <div
          key={index}
          className="flex flex-row items-center justify-between p-3 border-b-2 border-border-color"
        >
          <p className="text-center w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">
            {key}
          </p>
          <p className="text-center w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">
            {displayValue}
          </p>
          <p className="w-1/3 text-center overflow-hidden text-ellipsis whitespace-nowrap">
            {unit}
          </p>
        </div>
      );
    });
  };

  const elements = [
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[0px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m9b", "M9B")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[262px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m9_m9a", "M9-M9A")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[537px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m11_m11c", "M11-M11A-M11C")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[812px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m6a", "M6A")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[1087px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m4_m4a", "M4-M4A")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[1363px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m1a_m2b", "M1A-M2B")
    },
    {
      id: "fan-left",
      className: "absolute left-[597px] top-[1637px] w-[220px] h-[225px] cursor-pointer",
      onClick: () => onClick("m3", "M3")
    },
  ];

  useEffect(() => {
    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/calculatedbop/calculations/`);

    eventSource.addEventListener("periodic-event-bop-calculations", (e) => {

      try {
        const jsonData = JSON.parse(e.data);
        setCalculatedValues(jsonData);
        console.log(jsonData)
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };

  }, []);


  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="w-full h-full flex flex-1 overflow-hidden relative"
      id="wrapper"
    >
      <QuickPinchZoom
        tapZoomFactor={0}
        centerContained
        minZoom={-4}
        maxZoom={4}
        onUpdate={onUpdate}
      >
        <div
          id="wrapper"
          ref={divRef}
          className="w-[3780px] h-[1960px] relative "
        >

          {/* input 
          <div
            className={`absolute left-[1686px] top-[1227px] min-w-[200px] h-10 bg-table-head text-text-color flex items-center justify-center rounded-sm border border-text-color p-4`}
          >
            <p className="text-3xl font-semibold">
              250 t/h
            </p>
          </div>
*/}


          {boppositions[company].calculationPositions.map((item, index) => {

            const boruName = item.value.split(' ')[0] + ' ' + item.value.split(' ')[1]; // "Boru 1", "Boru 2", etc.

            // Get the corresponding data object for the boru (e.g., Boru 1, Boru 2)
            const data = calculatedValues[boruName];  // Get the data for Boru 1, Boru 2, etc.

            // Get the correct value (V, P_Ktoplam, or Elektrik_Motor_Gücü) based on the item
            let dataValue = '00.00';  // Default value in case of missing data
            if (item.value.includes('Elektrik_Motor_Gücü')) {
              dataValue = data ? data.Elektrik_Motor_Gücü : '00.00';
            } else if (item.value.includes('P_Ktoplam')) {
              dataValue = data ? data.P_Ktoplam : '00.00';
            } else if (item.value.includes('V')) {
              dataValue = data ? data.V : '00.00';
            }



            return (
              <div
                key={index}
                className={`absolute left-[${item.left}px] top-[${item.top}px] min-w-[200px] h-10 bg-table-head text-text-color flex items-center justify-center rounded-sm border border-text-color p-1`}
                style={{
                  transform: `translate(${item.left}px, ${item.top}px)`,
                }}
              >

                <p className="text-3xl font-semibold">
                  <span> {item.symbol && item.symbol}</span>
                  <span> {dataValue}</span>
                  <span> {item.brim}</span>
                </p>

              </div>
            );
          })}



          {theme && (
            <img
              className="w-[3780px] h-[1960px]"
              src={
                theme === "dark"
                  ? photoMap.bop[company].dark
                  : photoMap.bop[company].light
              }
              alt="orc-page.png"
            />
          )}
          <div>

            {elements.map((el) => (
              <div
                key={el.id}
                id={el.id}
                className={el.className}
                onClick={el.onClick}
              />
            ))}


          </div>
        </div>
      </QuickPinchZoom>

      {isOpen && (
        <Draggable cancel=".cancel-drag">
          <div
            ref={modalRef}
            className="bg-sideheadbar-color rounded-md text-text-color 
       flex flex-col absolute w-full xl:w-[40%] z-10 self-center p-6 xl:right-[29%] shadow-xl shadow-background-color"
          >
            <div className="bg-border-color rounded-md flex flex-row items-center justify-between p-2 px-4 mb-3">
              <h4 className="3xl:text-4xl font-semibold">{modalTitle}</h4>
              <button
                className="cancel-drag absolute right-8 text-gray-400 bg-border-color  hover:text-gray-900 rounded-lg text-sm w-7 h-7 xl:w-10 xl:h-10 ml-auto inline-flex justify-center items-center dark:hover:text-white"
                onClick={(e) => {
                  e.stopPropagation(); // Olayın yukarıya iletilmesini engelle
                  setIsOpen(false);
                }}
              >
                <IoIosClose className="hover:scale-125" size={25} />
              </button>
            </div>
            <div className="flex flex-row justify-between p-3 border-2 border-border-color text-lg 3xl:text-2xl 4xl:text-5xl font-bold rounded-t-md ">
              <p className="w-1/3 text-center">Property</p>
              <p className="w-1/3 text-center">Value</p>
              <p className="w-1/3 text-center">Unit</p>
            </div>
            <div className="flex flex-1 flex-col text-sm 3xl:text-lg 4xl:text-4xl justify-evenly  border-2 border-border-color rounded-b-md">
              {renderDataModal()} {/* Render the data dynamically */}
            </div>
          </div>
        </Draggable>
      )}


      {/* Open button -> Notification modal  */}
      <div
        onClick={() => setNotificationModal(true)}
        className="absolute animate-bounce bottom-5 right-5 bg-table-head hover:scale-125 cursor-pointer w-10 h-10 3xl:w-20 3xl:h-20 rounded-lg flex items-center justify-center"
      >
        <BsExclamationCircle className="text-logo-color text-xl 3xl:text-4xl" />
      </div>

      {/* Notification table  */}
      <div
        className={`bg-sideheadbar-color border border-border-color absolute bottom-0 right-0 w-full z-30 flex flex-col h-2/3 animate-slideUp ${notificationModal ? "flex" : "hidden"
          }`}
      >
        <div className="flex w-full items-center h-12 3xl:h-22 justify-end p-3">
          <button
            onClick={() => setNotificationModal(false)}
            type="button"
            class="absolute top-3 right-3 text-text-color hover:bg-table-head  rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
          >
            <IoClose size={28} />
          </button>
        </div>

        <div className="overflow-hidden flex flex-1 my-3 w-[98%] h-[98%] self-center">
          <NotificationTable />
        </div>
      </div>
    </div>
  );
};

export default Bop;
