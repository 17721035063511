import React, { useEffect } from "react";
import PieChart from "../components/charts/PieChart";
import LineChart from "../components/charts/LineChart";
import BarChart from "../components/charts/BarChart";
import { colors } from "../consts/colors";
import { useState } from "react";
import AreaChart from "../components/charts/AreaChart";
import ElectricityConsumptionsBarChart from "../components/charts/ElectricityConsumptionsBarChart";
import { useAuth } from "../providers/AuthProvider";
import { useTranslation } from 'react-i18next';
import MultiSelectBox from "../components/selectBoxes/TableMultiSelectBox";
import TableMultiSelectBox from "../components/selectBoxes/TableMultiSelectBox";
import DashboardSelectBox from "../components/selectBoxes/DashboardSelectBox";
import { useSelector } from "react-redux";
import IntervalSelectBox from "../components/selectBoxes/IntervalSelectBox";
import DashboardIntervalSelectBox from "../components/selectBoxes/DashboardIntervalSelectBox";
import LineChartCustom from "../components/charts/LineChartCustom";
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import ChartTypeSelectBox from "../components/selectBoxes/ChartTypeSelectBox";
import BarChartDash from "../components/charts/BarChartDash";
import ScatterChartDash from "../components/charts/ScatterChartDash";
import PeriodSelectBox from "../components/selectBoxes/PeriodSelectBox";
import { useRef } from "react";


const colorsChart = [
  "rgba(255, 0, 0, 1)",
  "rgba(0, 255, 0, 1)",
  "rgba(0, 85, 255, 1)",
  "rgba(255, 200, 0, 1)",
  "rgba(255, 0, 255, 1)",
  "rgba(0, 255, 255, 1)",
];

const Dashboard = () => {
  const { t } = useTranslation();
  const xlabelsBar1 = [
    t('months.January'),
    t('months.February'),
    t('months.March'),
    t('months.April'),
    t('months.May'),
    t('months.June'),
    t('months.July'),
    t('months.August'),
    t('months.September'),
    t('months.October'),
    t('months.November'),
    t('months.December'),
  ];


  const xlabelsBar2 = [
    t('date.date-sunday'),
    t('date.date-monday'),
    t('date.date-tuesday'),
    t('date.date-wednesday'),
    t('date.date-thursday'),
    t('date.date-friday'),
    t('date.date-saturday'),
  ];

  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [netWellProduction, setNetWellProduction] = useState([]);
  const [grossWellProduction, setGrossWellProduction] = useState([]);
  const [predictedMontlySales, setPredictedMontlySales] = useState([]);
  const [happenedMontlySales, setHappenedMontlySales] = useState([]);
  const [electricityConsumptions, setElectricityConsumptions] = useState([]);
  const [predictedWeeklySales, setPredictedWeeklySales] = useState([]);
  const [happenedWeeklySales, setHappenedWeeklySales] = useState([]);
  const [grossPowerProduction, setGrossPowerProduction] = useState([]);
  const [netPowerProduction, setNetPowerProduction] = useState([]);
  const auth = useAuth();
  const labels = useSelector((state) => state.selectedOptions.tableValues);
  const period = useSelector((state) => state.selectedOptions.period);
  const [currentPage, setCurrentPage] = useState(0);
  const [charts, setCharts] = useState([]);
  const { token } = useAuth();
  const [chartData, setChartData] = useState(null);
  const [groupedCharts, setGroupedCharts] = useState({ 0: [] });
  const chartsPerPage = 4;
  const [currentTab, setCurrentTab] = useState(0);
  const [isAddChartModalOpen, setIsAddChartModalOpen] = useState(false);
  const [tabs, setTabs] = useState([])
  const [deleteTabId, setDeleteTabId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTabName, setNewTabName] = useState("");
  const [isAddingTab, setIsAddingTab] = useState(false);
  const chartType = useSelector((state) => state.selectedOptions.chartType);
  const [chartTitle, setChartTitle] = useState("");
  const [interval, setInterval] = useState("");
  const addChartModalRef = useRef(null);
  const [happenedDailySales, setHappenedDailySales] = useState([]);
  const [predictedDailySales, setPredictedDailyales] = useState([]);
  const [labelsPredictions, setLabelsPredictions] = useState([]);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await fetch('https://dashboard.thermosoft.com.tr/api/prediction/sales-data', {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log(data)
        // Parse the response to split labels, real, and predicted data
        const formattedLabels = [];
        const realSales = [];
        const predictedSales = [];

        Object.entries(data).forEach(([timestamp, values]) => {

          const date = new Date(timestamp);
          const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:00`;

          formattedLabels.push(formattedDate);
          realSales.push(values[0]);
          predictedSales.push(values[1]);
        });

        setLabelsPredictions(formattedLabels);
        setHappenedDailySales(realSales);
        setPredictedDailyales(predictedSales);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, []);




  const handleInputChange = (e) => {
    setInterval(e.target.value);
  };


  useEffect(() => {
    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/well/netproductions`, { withCredentials: true });

    eventSource.addEventListener("periodic-event-wellnetproduction", (e) => {

      try {
        const jsonData = JSON.parse(e.data);
        if (jsonData) {
          const chartData = Object.keys(jsonData).map((wellName) => ({
            name: wellName,
            value: jsonData[wellName]?.toFixed(0),
          }));
          setNetWellProduction(chartData);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/well/grossproductions`, { withCredentials: true });

    eventSource.addEventListener("periodic-event-wellgrossproduction", (e) => {

      try {
        const jsonData = JSON.parse(e.data);
        if (jsonData) {
          const chartData = Object.keys(jsonData).map((wellName) => ({
            name: wellName,
            value: jsonData[wellName]?.toFixed(0),
          }));
          setGrossWellProduction(chartData);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/well/espconsumptions`, { withCredentials: true });

    eventSource.addEventListener("periodic-event-espconsumption", (e) => {
      try {
        const jsonData = JSON.parse(e.data);
        console.log(jsonData)
        if (jsonData) {
          const chartData = Object.keys(jsonData).map((wellName) => ({
            name: wellName,
            value: jsonData[wellName]?.toFixed(0),
          }));

          setElectricityConsumptions(chartData);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handleAddTab = async () => {
    if (!newTabName.trim()) {
      alert("Lütfen bir isim giriniz!");
      return;
    }

    if (newTabName.trim().length > 10) {
      alert("Tab adı en fazla 10 karakter olabilir!");
      return;
    }

    try {
      const response = await fetch("https://dashboard.thermosoft.com.tr/api/tabs", {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newTabName.trim() }),
      });

      if (response.ok) {
        const newTab = await response.json();
        setCurrentTab(newTab.id);
        setNewTabName("");
        setIsAddingTab(false);
        await fetchTabs();
        await fetchData();
      }

  
    } catch (error) {
      console.error("Error adding tab:", error);
    }
  };

  const confirmDelete = (tabId) => {
    setDeleteTabId(tabId);
    setIsPopupOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteTab(deleteTabId);
    setIsPopupOpen(false);
    setDeleteTabId(null);
  };

  const handleCancelDelete = () => {
    setIsPopupOpen(false);
    setDeleteTabId(null);
  };

  const toggleAddChartModal = () => {
    setIsAddChartModalOpen(!isAddChartModalOpen);
  };

  // Modal dışında bir yere tıklandığında kapatma
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addChartModalRef.current &&
        !addChartModalRef.current.contains(event.target)
      ) {
        setIsAddChartModalOpen(false);
      }
    };

    if (isAddChartModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddChartModalOpen]);

  const fetchData = async () => {
    try {
      const response = await fetch("https://dashboard.thermosoft.com.tr/api/chart/fetch-all");
      const result = await response.json();
      console.log(result)
      if (Object.keys(result).length > 0) {
        setChartData(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTabs();
    fetchData();
  }, []);

  useEffect(() => {
    if (chartData) {
      if (!chartData || typeof chartData !== "object") {
        console.error("chartData is undefined or not an object");
        return;
      }

      const grouped = { 0: [] }; // Default Tab (0)

      Object.entries(chartData).forEach(([chartId, chartValues]) => {
        const { tab, data: chartSeriesData, period, chartTitle, chartType } = chartValues;

        // Tab bilgisine göre gruplama
        const tabIndex = tab || 0; // Varsayılan olarak Default Tab (0)
        if (!grouped[tabIndex]) {
          grouped[tabIndex] = [];
        }

        const series = Object.entries(chartSeriesData).map(([label, values]) => ({
          label,
          data: values.map(([datetime, value]) => ({
            x: new Date(datetime),
            y: value,
          })),
        }));

        grouped[tabIndex].push({ chartId, series, period, chartTitle, chartType });
      });

      setGroupedCharts(grouped);
    }
  }, [chartData]);

  const handleDeleteTab = async (deleteTabId) => {

    try {
      const response = await fetch(`https://dashboard.thermosoft.com.tr/api/tabs/${deleteTabId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response)
      if (response.ok) {
        await fetchTabs(); // Tab listesini yenile
        await fetchData(); // Grafik verilerini yenile
      } else {
        const message = await response.text();
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const handleDelete = async (chartId) => {

    try {
      const response = await fetch(`https://dashboard.thermosoft.com.tr/api/chart/deleteById/${chartId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        await fetchTabs(); // Tab listesini yenile
        await fetchData(); // Grafik verilerini yenile

      } else {
        const message = await response.text();
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const handleCreateChart = async () => {
    if (!chartTitle.trim() || labels.length === 0 || !period) {
      alert("Please provide a chart title, select labels, and period before creating a chart.");
      return;
    }

    const labelTableMapping = {};
    labels.forEach((label, index) => {
      if (label.tableName && label.columnName) {
        // Use tableName and columnName directly
        labelTableMapping[label.columnName] = label.tableName;
      } else {
        console.error(`Label at index ${index} is missing required properties:`, label);
      }
    });

    const periodValue = period.value;

    try {
      const saveResponse = await fetch("https://dashboard.thermosoft.com.tr/api/chart", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          labelTableMapping,
          interval: interval,
          tabId: currentTab,
          chartType: chartType.value,
          chartTitle: chartTitle,
          period: periodValue
        }),
      });

      if (!saveResponse.ok) throw new Error("Failed to save chart configuration");

      const chartData = await saveResponse.json();

      setCharts([...charts, chartData]);
      setCurrentPage(Math.ceil(charts.length / chartsPerPage) + 1);
      await fetchData(); // Refresh chart data
    } catch (error) {
      console.error("Error adding chart:", error);
      alert("An error occurred while adding the chart.");
    }
  };

  useEffect(() => {
    window.addEventListener("theme", () => {
      setTheme(localStorage.getItem("theme"));
    });
  }, []);

  const fetchTabs = async () => {
    try {

      const response = await fetch("https://dashboard.thermosoft.com.tr/api/tabs",
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      if (!response.ok) {

        throw new Error("Failed to fetch tabs");
      }

      const data = await response.json();

      setTabs(data);
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  return (
    <div className="text-text-color w-full h-full gap-3 flex flex-col ">
      <div className="flex flex-row justify-between items-center">
        <div className="bg-background-color text-text-color flex gap-2 justify-start flex-1 overflow-x-auto">
          {/* Default Tab */}
          <button
            onClick={() => setCurrentTab(0)}
            className={`px-4 py-2 ${currentTab === 0 ? "bg-border-color text-text-color" : "bg-sideheadbar-color"
              } rounded-md`}
          >
            {t("default")}
          </button>

          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`flex items-center gap-2 p-1 ${currentTab === tab.id
                ? "bg-border-color text-text-color"
                : "bg-sideheadbar-color"
                } rounded-md`}
            >
              <button onClick={() => setCurrentTab(tab.id)}> {tab.name}</button>
              {
                (auth.userRole === "ADMIN" || auth.userRole === "SUPERADMIN") &&
                <div
                  onClick={() => confirmDelete(tab.id)}
                  className="cursor-pointer bg-[#E51F21] bg-opacity-10 p-1 rounded-full flex items-center justify-center hover:scale-125"
                >
                  <IoClose color="#E51F21" size={18} />
                </div>}
            </div>
          ))}

          {isAddingTab ? (
            <div className="flex items-center gap-2 bg-sideheadbar-color px-2 rounded-md border border-border-color ">
              <input
                type="text"
                value={newTabName}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 10) {
                    setNewTabName(value);
                  }
                }}
                placeholder={t("tab-name-input")}
                maxLength={10}
                className=" py-1 rounded-md  text-text-color bg-sideheadbar-color outline-none"
              />
              <button
                onClick={handleAddTab}
                className="px-3 py-1 bg-blue-500 text-white rounded-md"
              >
                {t("add")}
              </button>
              <button
                onClick={() => setIsAddingTab(false)} // Tab ekleme modunu iptal et
                className="px-3 py-1 bg-gray-300 text-black rounded-md"
              >
                {t("cancel")}
              </button>
            </div>
          ) : ((auth.userRole === "ADMIN" || auth.userRole === "SUPERADMIN") &&
            <button
              onClick={() => setIsAddingTab(true)} // Tab ekleme modunu aç
              className="bg-logo-color bg-opacity-10 rounded-full w-10 h-10 flex items-center justify-center"
            >
              <FiPlus size={28} className="text-logo-color" />
            </button>
          )}


          {/* Silme Onay Popup */}
          {isPopupOpen && (
            <div className="fixed inset-0 bg-sideheadbar-color bg-opacity-25 flex items-center justify-center">
              <div className="bg-sideheadbar-color border border-border-color p-10 rounded-md shadow-lg">
                <h3 className="text-lg mb-4">{t("delete-tab-question")}</h3>
                <div className="flex gap-4">
                  <button
                    onClick={handleConfirmDelete}
                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                  >
                    {t("yes")}
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    className="px-4 py-2 bg-gray-300 text-black rounded-md"
                  >
                    {t("no")}
                  </button>
                </div>
              </div>
            </div>
          )}


        </div>


        {(auth.userRole === "ADMIN" || auth.userRole === "SUPERADMIN") && (
          <div className="flex flex-row gap-3 relative">
            {/* Add Chart Button */}
            <button
              onClick={toggleAddChartModal} // Toggle modal visibility
              className="w-[100px] border border-border-color h-10 rounded-md z-50 flex items-center justify-center cursor-pointer bg-sideheadbar-color text-text-color"
            >
              {t("add-chart")}
            </button>

            {/* Add Chart Modal */}
            {isAddChartModalOpen && (
              <div  ref={addChartModalRef}  className="absolute flex rounded-md flex-col gap-3 top-12 w-[90vw] md:w-[400px] right-0 z-30 p-3 bg-sideheadbar-color border border-border-color shadow-lg">
                {/* Chart Type Selection */}
                <div className="h-12 mb-4 z-50">
                  <label className="block text-sm text-text-color">{t("chart-type")}:</label>
                  <ChartTypeSelectBox />
                </div>

                {/* Table Name Input */}
                <div className="h-12 mb-2 z-40">
                  <label htmlFor="chartTitle" className="block text-sm text-text-color ">{t("chart-name")}:</label>
                  <input
                    id="chartTitle"
                    type="text"
                    className="w-full p-2 border border-border-color rounded-md bg-sideheadbar-color text-text-color outline-none"
                    value={chartTitle}
                    onChange={(e) => setChartTitle(e.target.value)}
                    placeholder={t("enter-chart-name")}
                  />
                </div>

                {/* Values Selection */}
                <div className="h-12 mb-2 z-30">
                  <label className="block text-sm text-text-color">{t("values")}:</label>
                  <DashboardSelectBox selectedColors={colors} />
                </div>

                {/* Interval Selection */}
                <div className="h-12  mb-4 z-20">
                  <label className="block text-sm text-text-color">{t("period")}:</label>
                  <PeriodSelectBox />
                </div>

                <div className="h-12  mb-4 z-10">
                  <label className="block text-sm text-text-color">{t("interval")}:</label>
                  <input
                    type="number"
                    id="intervalInput"
                    name="interval"
                    value={interval}
                    className="w-full p-2 border border-border-color text-text-color bg-background-color rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder={t("enter-number")}
                    min="1"
                    onChange={(e) => {
                      const value = Math.max(1, Number(e.target.value));
                      setInterval(value);
                    }}
                  />
                </div>


                {/* Buttons */}
                <button
                  onClick={handleCreateChart} // Trigger the chart creation
                  className="w-full p-1 rounded-md bg-blue-500 text-white"
                >
                  {t("create-chart")}
                </button>
                <button
                  onClick={() => setIsAddChartModalOpen(false)} // Close modal
                  className="w-full p-1 bg-red-500 text-white rounded-md"
                >
                  {t("close")}
                </button>
              </div>
            )}

          </div>
        )}

      </div>

      {/* First tab */}
      {
        currentTab === 0 &&
        <div className="w-full h-full grid lg:grid-rows-2 grid-cols-1 md:grid-cols-2  lg:grid-cols-5 gap-3">
          <div className="h-80 lg:h-full bg-sideheadbar-color border border-border-color rounded-md">
            {netWellProduction.length > 0 && (
              <PieChart
                data={netWellProduction.map((item) => item.value)}
                labels={netWellProduction.map((item) => item.name)}
                title={t("well.well-net-production")}
                backgroundColors={colors.chartBackgroundColors}
                theme={theme}
              />
            )}
          </div>
          <div className="h-80 lg:h-full bg-sideheadbar-color lg:col-span-2 border border-border-color rounded-md">
            <BarChart
              data1={happenedMontlySales && happenedMontlySales}
              data2={predictedMontlySales && predictedMontlySales}
              titleValue1={t('sales.real')}
              titleValue2={t('sales.predicted')}
              xAxisLabel={xlabelsBar1}
              title={t('sales.montly-sales')}
              theme={theme}
            />
          </div>
          <div className="h-80 lg:h-full bg-sideheadbar-color lg:col-span-2 border border-border-color rounded-md">
            <AreaChart
              theme={theme}
              title={t('hourly-production-comparison')}
              data1={predictedDailySales}
              data2={happenedDailySales}
              labels={labelsPredictions}
            />
          </div>
          <div className="h-80 lg:h-full bg-sideheadbar-color border border-border-color rounded-md">
            {grossWellProduction.length > 0 && (
              <PieChart
                data={grossWellProduction.map((item) => item.value)}
                labels={grossWellProduction.map((item) => item.name)}
                title={t("well.well-gross-production")}
                backgroundColors={colors.chartBackgroundColors}
                theme={theme}
              />
            )}
          </div>
          <div className="h-80 lg:h-full bg-sideheadbar-color md:col-span-2 border border-border-color rounded-md">
            {electricityConsumptions && (
              <ElectricityConsumptionsBarChart
                title="Electricity Consumption Chart"
                datas={electricityConsumptions.map((item) => item.value)}
                xAxisLabel={electricityConsumptions.map((item) => item.name)}
                theme={theme}
              />
            )}

          </div>
          <div className="h-80 lg:h-full bg-sideheadbar-color md:col-span-2 border border-border-color rounded-md">
            <LineChart
              data1={grossPowerProduction}
              data2={netPowerProduction}
              title={t('sales.power-production')}
              theme={theme}
            />
          </div>
        </div>
      }

      {/* Tab Content */}
      {
        currentTab !== 0 && (
          <div
            className={`grid grid-dash w-full h-[92%] gap-3`}
            style={{
              // Grid template hesaplamaları
              gridTemplateColumns: `repeat(${Math.ceil(Math.sqrt(groupedCharts[currentTab]?.length || 1))}, minmax(0, 1fr))`,
              gridTemplateRows: `repeat(${Math.ceil(
                groupedCharts[currentTab]?.length /
                Math.ceil(Math.sqrt(groupedCharts[currentTab]?.length || 0.5))
              )}, minmax(0, 1fr))`,
            }}
          >
            {groupedCharts[currentTab]?.map(({ chartId, series, period, chartTitle, chartType }) => (
              <div
                key={chartId}
                className="chart-container  bg-sideheadbar-color border border-border-color rounded-md "
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                {chartType === "LineChart" && <LineChartCustom
                  theme={theme}
                  chartId={chartId}
                  data={{
                    datasets: series.map((serie, index) => ({
                      label: serie.label,
                      data: serie.data,
                      borderColor: colorsChart[index % colorsChart.length],
                    })),
                    interval: period,
                    chartTitle: chartTitle,
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false, // Yükseklik oranını otomatik ayarlamak için
                    scales: {
                      x: { type: "time", time: { unit: period } },
                      y: { beginAtZero: true },
                    },
                  }}
                  handleDelete={handleDelete}
                />}
                {chartType === "BarChart" && (
                  <BarChartDash
                    theme={theme}
                    data={{
                      chartTitle,
                      interval: period,
                      labels: series.map((serie) => serie.label),
                      datasets: series.map((serie, index) => ({
                        label: serie.label,
                        data: serie.data.map((d) => ({ x: d.x, y: d.y })),
                        backgroundColor: colorsChart[index % colorsChart.length],
                      })),
                    }}
                    handleDelete={handleDelete}
                    chartId={chartId}
                  />
                )}
                {chartType === "ScatterChart" && (
                  <ScatterChartDash
                    theme={theme}
                    data={{
                      chartTitle,
                      interval: period,
                      datasets: series.map((serie, index) => ({
                        label: serie.label,
                        points: serie.data, // Scatter points: {x: value, y: value}
                        backgroundColor: colorsChart[index % colorsChart.length],
                        borderColor: colorsChart[index % colorsChart.length],
                      })),
                    }}
                    handleDelete={handleDelete}
                    chartId={chartId}
                  />
                )}

              </div>
            ))}
          </div>
        )
      }


    </div >
  );
};

export default Dashboard;
