import React, { useEffect, useState } from "react";
import chroma from "chroma-js";
import { colors } from "../../consts/colors";
import Select from "react-select";
import "./MultiSelectBox.css";
import { useDispatch } from "react-redux";
import { setChartValues, setValues } from "../../redux/selectedOptionsSlice";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../providers/AuthProvider";

const colorsField = [
  "rgba(255, 99, 71, 1)",
  "rgba(60, 179, 113, 1)",
  "rgba(71, 160, 255, 1)",
  "rgba(197, 71, 255, 1)",
  "rgba(255, 181, 0, 1)",
  "rgba(255, 102, 207, 1)",
];

const MultiSelectBox = ({ selectedColors ,theme}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const [fieldNames, setFieldNames] = useState([]);
  const { token } = useAuth();

  const textcolor =
  theme === "dark"
    ? colors.chart.dark["font-color"]
    : colors.chart.light["font-color"];

  useEffect(() => {
    dispatch(setChartValues(selectedOptions));
  }, [selectedOptions]);

  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await fetch("https://dashboard.thermosoft.com.tr/api/metadata/columns", {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)
        setFieldNames(data);
      } catch (error) {
        console.error("Error fetching columns:", error);
      }
    };

    fetchColumns();
  }, [token]);

  const options = Object.entries(fieldNames).flatMap(([tableName, columns], tableIndex) =>
    columns.map((columnName, columnIndex) => ({
      value: `${tableName}.${columnName}`,
      label: columnName, 
      color: colorsField[tableIndex % colorsField.length], 
      table:tableName
    }))
  );

  const handleSelectChange = (selected) => {
    if (selected.length <= 6) {
      setSelectedOptions(selected);
    } else {

    }
  };

  const isOptionDisabled = (option) => {
    return (
      selectedOptions.length >= 6 &&
      !selectedOptions.some(
        (selectedOption) => selectedOption.value === option.value
      )
    );
  };

  return (
    <div className="w-full !h-7 md:!h-10 3xl:!h-16 4xl:!h-20 bg-background-color z-30 ">
      <Select
        hideSelectedOptions={false}
        className="react-select-container"
        classNamePrefix="react-select"
        isSearchable={true}
        closeMenuOnSelect={false}
        isMulti
        placeholder={t('chart.select')}
        options={options}
        onChange={(o) => handleSelectChange(o)}
        isOptionDisabled={isOptionDisabled}
        isClearable={false}
        styles={{
          control: (provided, state) => ({
            ...provided,
            minWidth: 150,
            boxShadow: "none",
            color:textcolor
          }),
          input: (base) => ({
            ...base,
            color: textcolor, // Input metin rengi
            "::placeholder": {
              color: textcolor, // Placeholder rengi
            },
          }),
          menu: () => ({
            minWidth: 150,
            borderRadius: 1,
          }),
          option: (base) => ({
            ...base,
            borderBottom: `0.2px groove gray`,
            height: "100%",
          }),
          menuList: (base) => ({
            ...base,
            zIndex: 10,
            "::-webkit-scrollbar": {
              width: "7px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#1F1F1F",
              backgroundColor: "#343434",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#9A9A9A",
            },
          }),
          multiValue: (styles, { data }) => {
            const index = selectedOptions.findIndex(
              (option) => option.value === data.value
            );
            const backgroundColor = index >= 0 && selectedColors[index];

            return {
              ...styles,
              backgroundColor,
              width: 20,
              height: 20,
              alignItems: "center",
              justifyItems: "center",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            display: "none",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "white",
            ":hover": {
              color: "white",
              width: "120%",
              height: "120%",
            },
          }),
          clearIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            borderColor: colors["logo-color"],
            color: colors["logo-color"],
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            "&:hover": {
              color: colors["logo-color"],
            },
            color: colors["logo-color"],
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: colors["logo-color"],
          }),
        }}
      />
    </div>
  );
};

export default MultiSelectBox;
