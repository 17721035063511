import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { BsGeoAlt } from 'react-icons/bs';

const MobileWorkplaceSelector = ({role}) => {
    const [workplaces, setWorkplaces] = useState([]);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { token, setCompany } = useAuth();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null); // Seçilen işyeri butonuna referans

    // API çağrısı ve işyerlerini yükleme
    useEffect(() => {
        if (!token) return; // Eğer token yoksa API'yi çağırma
        fetch('https://dashboard.thermosoft.com.tr/api/company/companies', {
            method: 'GET',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                setWorkplaces(data);
                if (data.length > 0) {
                    setSelectedWorkplace(data[0]); // Varsayılan olarak ilk işyeri seçilir
                }
            })
            .catch(error => {
                console.error("Error fetching workplaces", error);
            });
    }, [token]);

    // Seçilen işyerini güncelle
    const handleSelect = (workplace) => {
        setSelectedWorkplace(workplace);
        setCompany(workplace.id);
        setIsDropdownOpen(false); // Dropdown'u kapat
    };

    // Dropdown açma/kapama
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Dış tıklama ile dropdown kapama
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Dropdown'u kapat
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div onClick={toggleDropdown} className="relative flex flex-row w-5/6 lg:w-3/4 h-10 px-3 items-center md:gap-2 md:px-2 rounded-sm cursor-pointer text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color" ref={dropdownRef}>
            {/* Seçilen işyeri logo veya ismi */}
            <div className="overflow-hidden flex items-center justify-center relative" ref={buttonRef} >
                {selectedWorkplace && (
                
                        <p className="font-semibold">{selectedWorkplace.company_name.toUpperCase()}</p>
                 
                )}
            </div>

            {/* Dropdown menü */}
            {(isDropdownOpen && role ==="SUPERADMIN") && (
                <div className="absolute w-full mt-2 bg-sideheadbar-color border-border-color border shadow-lg rounded-lg z-50 top-full left-0">
                    {workplaces.map(workplace => (
                        <div
                            key={workplace.id}
                            className={`flex items-center px-5 py-2 cursor-pointer border-b border-border-color ${workplace.company_name === selectedWorkplace.company_name
                                ? "bg-logo-color text-black"
                                : "text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color"}`}
                            onClick={() => handleSelect(workplace)}
                        >
                            <span className="text-text-color">{workplace.company_name.toUpperCase()}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MobileWorkplaceSelector;
