import React, { useEffect, useRef, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { MdElectricBolt, MdEnergySavingsLeaf } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Switch from "react-switch";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";
import { useAuth } from "../providers/AuthProvider";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { toggleLang } from "../redux/langSlice";
import WorkplaceSelector from "../components/WorkplaceSelector";
import MobileWorkplaceSelector from "../components/MobileWorkplaceSelector";




const Header = () => {
  const dispatch = useDispatch();
  const { checkLanguage } = useSelector((store) => store.checkLang);
  const { t, i18n } = useTranslation();
  const [datas, setDatas] = useState(null);
  const unit = "MW";
  const [selected, setSelected] = useState("dashboard");
  const [isOpened, setIsOpened] = useState(false);
  const sidebarRef = useRef(null);
  const location = useLocation();
  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const auth = useAuth();
  const [orcGrossProduction, setOrcGrossProduction] = useState()
  const [headerValues, setHeaderValues] = useState()
  const handleChange = (checked) => {
    setChecked(checked);
    changeTheme(checked);
    window.dispatchEvent(new Event("theme"));
  };

  const changeTheme = (checked) => {
    setTheme(checked ? "dark" : "light");

    if (checked === true) {
      document.querySelector("html").classList.remove("light");
      document.querySelector("html").classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector("html").classList.remove("dark");
      document.querySelector("html").classList.add("light");
      localStorage.setItem("theme", "light");
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const path = currentPath.substring(currentPath.lastIndexOf("/") + 1);
    path === "" ? setSelected("dashboard") : setSelected(path);
  }, [location.pathname]);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpened(false);
    }
  };

  const items = [
    {
      label: t("dash.dash-dashboard"),
      isSelected: true,
      to: "",
    },
    {
      label: "ORC",
      isSelected: false,
      to: "orc",
    },
    {
      label: "BOP",
      isSelected: false,
      to: "bop",
    },
    {
      label: t("dash.dash-prediction"),
      isSelected: false,
      to: "prediction",
    },
    {
      label: t("dash.dash-chart"),
      isSelected: false,
      to: "chart",
    },
    {
      label: t("dash.dash-table"),
      isSelected: false,
      to: "table",
    },
    {
      label: "Administrator",
      isSelected: false,
      to: "administrator",
    },
  ];

  const bottomItems = [
    // {
    //   label: "Language",
    //   isSelected: false,
    // },
    {
      label: t("dash.dash-help"),
      isSelected: false,
      to: "help"
    },
    {
      label: t("dash.dash-settings"),
      isSelected: false,
    }
  ];

  const labels = [

    t("header.header-orc-gross"),
    t("header.header-orc-net"),
    t("header.header-orc-powerplant"),

    t("header.header-orc-grossefficiency"),
    t("header.header-orc-netefficiency"),

    t("header.header-orc-plantnetefficiency"),
  ];
  const labelsMobile = ["OGP", "ONP", "PPNP", "OGE", "ONE", "PPNE"];
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const connectEventSource = () => {
      const eventSource = new EventSource(
        `https://dashboard.thermosoft.com.tr/api/santral/headervalues`,
        { withCredentials: true }
      );

      eventSource.addEventListener("periodic-event-genactive", (e) => {
        try {
          const jsonData = JSON.parse(e.data);
    
          setHeaderValues(jsonData);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      });

      eventSource.onerror = () => {
   
        eventSource.close();
        setTimeout(connectEventSource, 5000); // 5 saniye sonra yeniden bağlan
      };

      return eventSource;
    };

    const eventSource = connectEventSource();

    return () => {
      eventSource && eventSource.close(); // Temizleme işlemi
    };
  }, []);

  // Language
  const handleChangeLang = () => {
    const newLang = checkLanguage ? 'en' : 'tr';
    i18n.changeLanguage(newLang);
    dispatch(toggleLang());
  };


  const handleLogout = () => {
    auth.logOut();
  };


  return (
    <>
      <div className=" hidden w-full sticky top-0 z-10 h-[90px] md:h-[70px] 3xl:h-[120px] bg-sideheadbar-color border-b border-border-color md:flex  sm:flex-row pr-2 space-x-2 text-text-color">
        <div className="xl:w-44 3xl:w-60 5xl:w-80 flex items-center justify-center">
          <img
            alt="logo"
            src={require("../assets/logo.png")}
            className="w-24 h-16 xs:w-20 xs:h-12 3xl:w-44 3xl:h-28 self-center"
          />
        </div>
        <div className="flex-1 flex flex-row items-center justify-between gap-3 pl-3 ">
          {/*     {labels.map((item, index) => (
            <div className="flex flex-col justify-around dark:bg-component-color bg-white border border-border-color rounded-md h-[65%] md:h-2/3 w-[20%] px-1 py-[1px] 3xl:p-4">
              <div className="flex flex-row justify-between items-start h-1/2">
                <p className="text-[8px] md:text-[9px] xl:text-[12px] 2xl:text-sm 3xl:text-xl 4xl:text-3xl">
                  {item}
                </p>
                {unit == "MW" ? (
                  <MdElectricBolt className="fill-logo-color 3xl:scale-150" />
                ) : (
                  <MdEnergySavingsLeaf className="fill-logo-color" />
                )}
              </div>

              <div className="flex flex-row justify-end items-end xs:pr-2 h-1/2">
                <p className="text-xs 2xl:text-base mx-1 font-semibold 3xl:text-3xl 4xl:text-5xl">
                  20.56
                </p>
                <p className="xs:text-xs text-[10px] text-light_gray 2xl:mb-[2px] 3xl:text-xl">
                  MW  
                </p>
              </div>
            </div>
          ))} {datas ? datas[index] : "00.00"} */}

          {headerValues && headerValues.power_plant_net_production !== undefined && (
            headerValues.power_plant_net_production >= 100 ? (
              labels.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-around dark:bg-component-color bg-white border border-border-color rounded-md h-[65%] md:h-2/3 w-[20%] px-1 py-[1px] 3xl:p-2"
                >
                  <div className="flex flex-row justify-between items-start h-1/2">
                    <p className="text-[8px] md:text-[9px] xl:text-[12px] 2xl:text-sm 3xl:text-xl 4xl:text-xl">
                      {item}
                    </p>
                    {index <= 2 ? (
                      <MdElectricBolt className="fill-logo-color 3xl:scale-150" />
                    ) : (
                      <MdEnergySavingsLeaf className="fill-logo-color" />
                    )}
                  </div>

                  <div className="flex flex-row justify-end items-end xs:pr-2 h-1/2">
                    <p className="text-xs 2xl:text-base mx-1 font-semibold 3xl:text-3xl 4xl:text-3xl">
                      {headerValues ? (
                        index === 0
                          ? headerValues.gross_production.toFixed(0)
                          : index === 1
                            ? headerValues.net_production.toFixed(0)
                            : index === 2
                              ? headerValues.power_plant_net_production.toFixed(0)
                              : index === 3
                                ? (headerValues.gross_efficiency * 100).toFixed(2)
                                : index === 4
                                  ? (headerValues.net_efficiency * 100).toFixed(2)
                                  : index === 5
                                    ? (headerValues.power_plant_net_efficiency * 100).toFixed(2)
                                    : "00.00"
                      ) : (
                        "00.00"
                      )}
                    </p>
                    <p className="xs:text-xs text-[11px] text-light_gray 2xl:mb-[2px] 3xl:text-xl">
                      {index <= 2 ? "KW" : "%"}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center h-full text-red-500 font-bold text-center text-xl">
                {t("header.header-error")} {/* Hata mesajı */}
              </div>
            )
          ) }



        </div>
      </div>

      {/* Header in mobile view */}
      <div className="w-full h-[110px] md:h-[70px] bg-sideheadbar-color text-text-color border-b border-border-color md:hidden text-[8px]">
        <div className="w-full h-1/3 flex flex-row items-center js p-2 pt-3">
          <FiMenu onClick={() => setIsOpened(!isOpened)} size={28} />
          <div className="flex flex-1 justify-center items-center">
            <img
              alt="logo1"
              src={require("../assets/logo1.png")}
              className="w-36"
            />
          </div>
        </div>

        {/* Values view in mobile view */}
        <div className="w-full h-2/3 grid grid-cols-6 xxs:space-x-0.5 space-x-1 content-around px-1">
          {labelsMobile.map((item, index) => (
            <div key={index} className="flex flex-col justify-around dark:bg-component-color border border-border-color rounded-md px-1 h-14 xs:text-[16px] sm:text-sm">
              <div className="flex flex-row items-center justify-between">
                <p className="text-[9px] xs:text-[12px] sm:text-sm">{item}</p>
                {index <= 2 ? (
                  <MdElectricBolt
                    size={18}
                    className="fill-logo-color xxs:scale-50 "
                  />
                ) : (
                  <MdEnergySavingsLeaf
                    size={18}
                    className="fill-logo-color xxs:scale-50"
                  />
                )}
              </div>
              <div className="flex flex-row items-center self-center">
                <p className="sm:mx-1 mx-0.5 text-[10px] xs:text-[12px] sm:text-lg font-semibold">
                  {headerValues ? (
                    index === 0 ? headerValues.gross_production :
                      index === 1 ? headerValues.net_production.toFixed(0) :
                        index === 3 ? (headerValues.gross_efficiency * 100).toFixed(2) :
                          index === 4 ? (headerValues.net_efficiency * 100).toFixed(2) : "00.00"
                  ) : "00.00"}
                </p>
                <p className="text-gray-500 text-[7px]  xs:text-[10px] sm:text-xs ">
                  {index <= 2 ? "MW" : "%"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Sidebar in mobile view  */}
      {isOpened && (
        <div
          ref={sidebarRef}
          className="w-1/2 min-h-full transition md:hidden bg-sideheadbar-color absolute top-0 left-0 shadow-md text-xs sm:text-sm text-text-color z-50 flex flex-col items-center"
        >
          <div
            onClick={() => setIsOpened(!isOpened)}
            className="absolute right-3 top-3"
          >
            <RxCross2 size={24} className="text-text-color" />
          </div>
          <div className="w-full flex flex-col flex-1 items-center justify-between mt-7">
            <div className="flex items-center flex-col w-full">
              {items.map((item, index) => (
                (item.label !== "Administrator" || auth.userRole === "SUPERADMIN" || auth.userRole === "ADMIN") && (<NavLink
                  key={index}
                  onClick={() => {
                    setIsOpened(!isOpened);
                    setSelected(item.label);
                  }}
                  to={item.to}
                  className={`flex flex-col w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal md:gap-2 px-3 rounded-sm cursor-pointer hover:bg-component-color
               ${item.label.toLowerCase() === selected.toLowerCase()
                      ? "text-logo-color"
                      : "text-text-color"
                    }`}
                >
                  <p className="block sm:text-xs lg:text-sm font-semibold">
                    {item.label}
                  </p>
                </NavLink>)
              ))}

              <MobileWorkplaceSelector role={auth.userRole} />
            </div>
            <div className="flex items-center w-full flex-col border-t border-border-color">

              {bottomItems.map((item, index) => (
                <NavLink
                  key={index}
                  onClick={() => {
                    setIsOpened(!isOpened);
                    setSelected(item.label);
                  }}
                  to={item.to}
                  className={`flex flex-col w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal md:gap-2 px-3 rounded-sm cursor-pointer hover:bg-component-color
               ${item.label.toLowerCase() === selected.toLowerCase()
                      ? "text-logo-color"
                      : "text-text-color"
                    }`}
                >
                  <p className="block sm:text-xs lg:text-sm font-semibold">
                    {item.label}
                  </p>
                </NavLink>
              ))}

              <NavLink

                onClick={handleLogout}
                className={`flex flex-col w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal md:gap-2 px-3 rounded-sm cursor-pointer hover:bg-component-color
                  `}
              >
                <p className="block sm:text-xs lg:text-sm font-semibold">
                  Logout
                </p>
              </NavLink>
            </div>
          </div>
          <div className='switch mt-5 border-[3px] rounded-full border-border-color relative mb-2'>
            <input type="checkbox" id="language-toggle" className='check-toggle absolute -ml-[9999px] invisible  check-toggle-round-flat' onChange={handleChangeLang} checked={checkLanguage} />
            <label htmlFor="language-toggle"></label>
            <span className='on'>Eng</span>
            <span className='off'>Tr</span>
          </div>
          <div className="flex flex-row items-center space-x-1 text-xs text-text-color">
            <text>Light</text>
            <div className="border-[3px] rounded-full border-border-color">
              <Switch
                draggable={true}
                handleDiameter={20}
                height={26}
                width={50}
                offColor="#ffffff"
                onColor="#161616"
                onHandleColor="#FFC700"
                offHandleColor="#FFC700"
                checked={checked}
                onChange={handleChange}
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      paddingRight: 2,
                    }}
                    className="text-text-color"
                  >
                    <BsFillMoonFill size={14} />
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      paddingRight: 2,
                    }}
                    className="text-text-color"
                  >
                    <BsFillSunFill size={18} />
                  </div>
                }
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                className="react-switch"
                id="icon-switch"
              />
            </div>
            <text>Dark</text>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
