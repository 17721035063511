import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colors } from '../../consts/colors';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaChart = ({ theme, title, data1, data2, labels }) => {
  const { t } = useTranslation();
  const color = theme === 'dark' ? colors.chart.dark['font-color'] : colors.chart.light['font-color'];
  const gridColor = theme === 'dark' ? colors.chart.dark['grid-color'] : colors.chart.light['grid-color'];

  return (
    <Line
      data={{
        labels, // Use dynamic labels
        datasets: [
          {
            fill: true,
            label: t('sales.predicted'),
            data: data1, // Predicted values
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: t('sales.real'),
            data: data2, // Real values
            borderColor: 'rgb(255, 181, 0)',
            backgroundColor: 'rgba(255, 181, 0, 0.5)',
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        color: color,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: title,
            color: color,
          },
        },
        scales: {
          x: {
            ticks: {
              color: color,
            },
            grid: {
              display: true,
              tickColor: color,
              drawOnChartArea: true,
              color: gridColor,
            },
            border: {
              color: color,
              display: true,
            },
          },
          y: {
            ticks: {
              color: color,
            },
            grid: {
              display: true,
              tickColor: color,
              drawOnChartArea: true,
              color: gridColor,
            },
            border: {
              color: color,
            },
          },
        },
      }}
    />
  );
};

export default AreaChart;
