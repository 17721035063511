import React from "react";
import ReactDatePicker from "react-datepicker";
import "./datetimePicker.css";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({ onChange, startDate, setStartDate }) => {
    const handleDateChange = (date) => {
        setStartDate(date); // Seçilen tarihi kendi local state'te güncelleyin
        onChange(date); // Tarihi üst bileşene iletin
    };

    // Özel bir input tanımlayarak yazılabilirliği kaldırıyoruz
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button
            className="w-full h-12 flex justify-center items-center bg-sideheadbar-color border border-border-color rounded-md text-text-color focus:outline-none"
            onClick={onClick}
            ref={ref}
        >
            {value || "Tarih Seç"}
        </button>
    ));

    return (
        <div className="w-72">
            <ReactDatePicker
                selected={startDate}
                onChange={handleDateChange}
                timeInputLabel="Saat:"
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="dd/MM/yyyy HH:mm"
                timeIntervals={1}
                customInput={<CustomInput />}
            />
        </div>
    );
};

export default DateTimePicker;
