const boppositions = {
    1: {
        initialPositions: [
            {
                left: 1048,
                top: 135,
                value: "tt1262_tt1262_pv_out",
                brim: "°C"
            },
            {
                left: 1048,
                top: 185,
                value: "pt1282_pt1282_pv_out",
                brim: "bara"
            },
            {
                left: 394,
                top: 397,
                value: "tt1060_tt1060_pv_out",
                brim: "°C"
            },
            {
                left: 394,
                top: 447,
                value: "pt1180_pt1180_pv_out",
                brim: "bara"
            },
            {
                left: 361,
                top: 866,
                value: "fy1050_fy1050_pv_out",
                brim: "m³/h"
            },
            {
                left: 361,
                top: 1187,
                value: "tt1053_tt1053_pv_out",
                brim: "°C"
            },
            {
                left: 361,
                top: 1237,
                value: "pt1078_pt1078_pv_out",
                brim: "bara"
            },
            {
                left: 675,
                top: 568,
                value: "_0brs_tt306_0brs_tt306_pv_out",
                brim: "°C"
            },
            {
                left: 675,
                top: 621,
                value: "_0brs_pt308_0brs_pt308_pv_out",
                brim: "bara"
            },
            {
                left: 675,
                top: 872,
                value: "tt0352_tt0352_pv_out",
                brim: "°C"
            },
            {
                left: 675,
                top: 922,
                value: "pt0351_pt0351_pv_out",
                brim: "bara"
            },
            {
                left: 675,
                top: 1329,
                value: "tt1088_tt1088_pv_out",
                brim: "°C"
            },
            {
                left: 1189,
                top: 483,
                value: "tt1075_tt1075_pv_out",
                brim: "°C"
            },
            {
                left: 1189,
                top: 533,
                value: "pt1074_pt1074_pv_out",
                brim: "bara"
            },
            {
                left: 1278,
                top: 872,
                value: "tt1071_tt1071_pv_out",
                brim: "°C"
            },
            {
                left: 1278,
                top: 922,
                value: "pt1070_pt1070_pv_out",
                brim: "bara"
            },
            {
                left: 1272,
                top: 1177,
                value: "tt0354_tt0354_pv_out",
                brim: "°C"
            },
            {
                left: 1272,
                top: 1229,
                value: "pt0353_pt0353_pv_out",
                brim: "bara"
            },
            {
                left: 1983,
                top: 1229,
                value: "tt0361_tt0361_pv_out",
                brim: "°C"
            },
            {
                left: 1288,
                top: 1762,
                value: "_0brs_tt308a_0brs_tt308a_pv_out",
                brim: "°C"
            },
            {
                left: 1288,
                top: 1812,
                value: "_0brs_pt310a_0brs_pt310a_pv_out",
                brim: "bara"
            },
            {
                left: 2585,
                top: 135,
                value: "tt2262_tt2262_pv_out",
                brim: "°C"
            },
            {
                left: 2585,
                top: 185,
                value: "pt2282_pt2282_pv_out",
                brim: "bara"
            },
            {
                left: 2453,
                top: 483,
                value: "tt2075_tt2075_pv_out",
                brim: "°C"
            },
            {
                left: 2453,
                top: 533,
                value: "pt2074_pt2074_pv_out",
                brim: "bara"
            },
            {
                left: 2595,
                top: 1089,
                value: "tt0356_tt0356_pv_out",
                brim: "°C"
            },
            {
                left: 2595,
                top: 1141,
                value: "pt0355_pt0355_pv_out",
                brim: "bara"
            },
            {
                left: 2595,
                top: 1746,
                value: "_0brs_tt308b_0brs_tt308b_pv_out",
                brim: "°C"
            },
            {
                left: 2595,
                top: 1796,
                value: "_0brs_pt310b_0brs_pt310b_pv_out",
                brim: "bara"
            },
            {
                left: 2957,
                top: 1089,
                value: "tt2071_tt2071_pv_out",
                brim: "°C"
            },
            {
                left: 2957,
                top: 1141,
                value: "pt2070_pt2070_pv_out",
                brim: "bara"
            },
            {
                left: 3187,
                top: 397,
                value: "tt2060_tt2060_pv_out",
                brim: "°C"
            },
            {
                left: 3187,
                top: 447,
                value: "pt2180_pt2180_pv_out",
                brim: "bara"
            },
            {
                left: 3220,
                top: 863,
                value: "fy2050_fy2050_pv_out",
                brim: "m³/h"
            },
            {
                left: 3220,
                top: 1262,
                value: "tt2053_tt2053_pv_out",
                brim: "°C"
            },
            {
                left: 3220,
                top: 1312,
                value: "pt2077_pt2077_pv_out",
                brim: "bara"
            },
            {
                left: 2595,
                top: 1903,
                value: "ft0499_ft0499_pv_out",
                brim: "m³/h"
            },
            {
                left: 1574,
                top: 798,
                value: "_0hps_tit_305_ma_pv_out",
                brim: "°C"
            },
            {
                left: 1819,
                top: 99,
                value: "tt1150_tt1150_pv_out",
                brim: "°C",
                symbol: "T amb"
            },
            {
                left: 2234,
                top: 1229,
                value: "t_evap_lp_in",
                brim: "°C"
            },
            {
                left: 1574,
                top: 748,
                value: "_0hps_pit_311_ma_pv_out",
                brim: "bara"
            },

        ],
        calculationPositions: [
            {
                left: 1012,
                top: 104,
                value: "Boru 9 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1012,
                top: 149,
                value: "Boru 9 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1012,
                top: 194,
                value: "Boru 9 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1012,
                top: 366,
                value: "Boru 10 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1012,
                top: 411,
                value: "Boru 10 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1012,
                top: 456,
                value: "Boru 10 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1012,
                top: 641,
                value: "Boru 13 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1012,
                top: 686,
                value: "Boru 13 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1012,
                top: 731,
                value: "Boru 13 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 917,
                top: 916,
                value: "Boru 6 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 917,
                top: 961,
                value: "Boru 6 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 917,
                top: 1006,
                value: "Boru 6 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 882,
                top: 1191,
                value: "Boru 7 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 882,
                top: 1236,
                value: "Boru 7 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 882,
                top: 1281,
                value: "Boru 7 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1170,
                top: 1191,
                value: "Boru 8 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1170,
                top: 1236,
                value: "Boru 8 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1170,
                top: 1281,
                value: "Boru 8 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1440,
                top: 1191,
                value: "Boru 17 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1440,
                top: 1236,
                value: "Boru 17 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1440,
                top: 1281,
                value: "Boru 17 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1012,
                top: 1466,
                value: "Boru 3 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1012,
                top: 1511,
                value: "Boru 3 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1012,
                top: 1556,
                value: "Boru 3 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1390,
                top: 1466,
                value: "Boru 4 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1390,
                top: 1511,
                value: "Boru 4 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1390,
                top: 1556,
                value: "Boru 4 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 837,
                top: 1741,
                value: "Boru 1 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 837,
                top: 1786,
                value: "Boru 1 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 837,
                top: 1831,
                value: "Boru 1 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1090,
                top: 1741,
                value: "Boru 2 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1090,
                top: 1786,
                value: "Boru 2 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1090,
                top: 1831,
                value: "Boru 2 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1405,
                top: 429,
                value: "Boru 11 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1405,
                top: 474,
                value: "Boru 11 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1405,
                top: 519,
                value: "Boru 11 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1405,
                top: 653,
                value: "Boru 14 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1405,
                top: 698,
                value: "Boru 14 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1405,
                top: 743,
                value: "Boru 14 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1405,
                top: 831,
                value: "Boru 15 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1405,
                top: 876,
                value: "Boru 15 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1405,
                top: 921,
                value: "Boru 15 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1405,
                top: 1005,
                value: "Boru 16 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1405,
                top: 1050,
                value: "Boru 16 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1405,
                top: 1095,
                value: "Boru 16 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1706,
                top: 1236,
                value: "Boru 18 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1706,
                top: 1281,
                value: "Boru 18 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1706,
                top: 1326,
                value: "Boru 18 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1706,
                top: 1603,
                value: "Boru 19 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1706,
                top: 1648,
                value: "Boru 19 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1706,
                top: 1693,
                value: "Boru 19 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1881,
                top: 139,
                value: "Reinjection 33 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1881,
                top: 184,
                value: "Reinjection 33 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1881,
                top: 229,
                value: "Reinjection 33 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1881,
                top: 419,
                value: "Reinjection 26 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1881,
                top: 464,
                value: "Reinjection 26 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1881,
                top: 509,
                value: "Reinjection 26 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1881,
                top: 701,
                value: "Reinjection 17 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1881,
                top: 746,
                value: "Reinjection 17 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1881,
                top: 791,
                value: "Reinjection 17 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 1881,
                top: 1046,
                value: "Reinjection 1 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 1881,
                top: 1091,
                value: "Reinjection 1 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 1881,
                top: 1136,
                value: "Reinjection 1 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2377,
                top: 85,
                value: "Reinjection 34 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2377,
                top: 130,
                value: "Reinjection 34 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2377,
                top: 175,
                value: "Reinjection 34 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2948,
                top: 85,
                value: "Reinjection 35 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2948,
                top: 130,
                value: "Reinjection 35 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2948,
                top: 175,
                value: "Reinjection 35 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3383,
                top: 85,
                value: "Reinjection 36 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3383,
                top: 130,
                value: "Reinjection 36 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3383,
                top: 175,
                value: "Reinjection 36 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2377,
                top: 366,
                value: "Reinjection 27 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2377,
                top: 411,
                value: "Reinjection 27 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2377,
                top: 456,
                value: "Reinjection 27 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2949,
                top: 366,
                value: "Reinjection 29 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2949,
                top: 411,
                value: "Reinjection 29 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2949,
                top: 456,
                value: "Reinjection 29 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3384,
                top: 366,
                value: "Reinjection 31 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3384,
                top: 411,
                value: "Reinjection 31 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3384,
                top: 456,
                value: "Reinjection 31 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2166,
                top: 647,
                value: "Reinjection 18 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2166,
                top: 692,
                value: "Reinjection 18 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2166,
                top: 737,
                value: "Reinjection 18 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2589,
                top: 647,
                value: "Reinjection 20 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2589,
                top: 692,
                value: "Reinjection 20 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2589,
                top: 737,
                value: "Reinjection 20 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2994,
                top: 647,
                value: "Reinjection 23 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2994,
                top: 692,
                value: "Reinjection 23 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2994,
                top: 737,
                value: "Reinjection 23 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3399,
                top: 647,
                value: "Reinjection 25 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3399,
                top: 692,
                value: "Reinjection 25 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3399,
                top: 737,
                value: "Reinjection 25 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2387,
                top: 931,
                value: "Reinjection 2 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2387,
                top: 976,
                value: "Reinjection 2 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2387,
                top: 1021,
                value: "Reinjection 2 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2672,
                top: 1011,
                value: "Reinjection 7 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2672,
                top: 1056,
                value: "Reinjection 7 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2672,
                top: 1101,
                value: "Reinjection 7 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2936,
                top: 931,
                value: "Reinjection 3 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2936,
                top: 976,
                value: "Reinjection 3 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2936,
                top: 1021,
                value: "Reinjection 3 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3176,
                top: 931,
                value: "Reinjection 4 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3176,
                top: 976,
                value: "Reinjection 4 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3176,
                top: 1021,
                value: "Reinjection 4 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3411,
                top: 931,
                value: "Reinjection 5 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3411,
                top: 976,
                value: "Reinjection 5 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3411,
                top: 1021,
                value: "Reinjection 5 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2304,
                top: 1262,
                value: "Reinjection 15 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2304,
                top: 1307,
                value: "Reinjection 15 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2304,
                top: 1352,
                value: "Reinjection 15 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2682,
                top: 1262,
                value: "Reinjection 13 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2682,
                top: 1307,
                value: "Reinjection 13 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2682,
                top: 1352,
                value: "Reinjection 13 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 2986,
                top: 1262,
                value: "Reinjection 8 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 2986,
                top: 1307,
                value: "Reinjection 8 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 2986,
                top: 1352,
                value: "Reinjection 8 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
            {
                left: 3396,
                top: 1262,
                value: "Reinjection 10 V",
                brim: "m/s",
                symbol: "V:"
            },
            {
                left: 3396,
                top: 1307,
                value: "Reinjection 10 P_Ktoplam",
                brim: "bar",
                symbol: "∆P:"
            },
            {
                left: 3396,
                top: 1352,
                value: "Reinjection 10 Elektrik_Motor_Gücü",
                brim: "kW",
                symbol: "W:"
            },
        ],
    },
    2: {
        initialPositions: [],
        calculationPositions: [],
    },
};


export default boppositions;