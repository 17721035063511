import React, { useEffect, useRef, useState } from "react";
import DatePicker from "../components/selectBoxes/DatePicker";
import { BsDownload } from "react-icons/bs";
import CustomTable from "../components/tables/CustomTable";
import { useSelector } from "react-redux";
import IntervalSelectBox from "../components/selectBoxes/IntervalSelectBox";
import { downloadAsCsv, downloadExcel } from "../utils/downloadTable";
import TableMultiSelectBox from "../components/selectBoxes/TableMultiSelectBox";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../providers/AuthProvider";
import PeriodSelectBox from "../components/selectBoxes/PeriodSelectBox";

const colors = [
  "#2F82F9",
  "#FF5A5A",
  "#686DF7",
  "#8B9700",
  "#00B707",
  "#FF7E36",
];

const Table = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { token } = useAuth();
  const [rows, setRows] = useState([]);
  const dateRange = useSelector((state) => state.selectedOptions.date);
  const values = useSelector((state) => state.selectedOptions.tableValues);
  const period = useSelector((state) => state.selectedOptions.period);
  const [headCells, setHeadCells] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrror, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);

  useEffect(() => {
    const newHeadCells = values.map((item) => {
      const columnName = item.value && item.value.includes(".") ? item.value.split(".")[1] : item.value;

      return {
        id: columnName,
        label: columnName,
      };
    });
  
    const datetimeItem = {
      id: "zaman_etiketi",
      label: "zaman_etiketi",
    };
    newHeadCells.unshift(datetimeItem);
  
    setHeadCells(newHeadCells);
  }, [values]);
  
  
  useEffect(() => {
    const getDataByDateRange = async () => {
      if (!dateRange || !values || !period) {
        return;
      }
  
      const startDate = dateRange[0];
      const endDate = dateRange[1];
  
      try {
        setIsLoading(true);
  
        const tableColumnMap = values.reduce((acc, curr) => {
          const { table, value } = curr; 
        
          // Eğer `value` "table.column" formatında ise, sadece sütun adını alın
          const columnName = value.includes('.') ? value.split('.')[1] : value;
        
          if (!acc[table]) {
            acc[table] = [];
          }
          acc[table].push(columnName);
          return acc;
        }, {});
        
  
        const response = await fetch("https://dashboard.thermosoft.com.tr/api/chart/fetchselected", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableColumnMap,
            startDate,
            endDate,
            interval: period.value,
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const fetchedData = await response.json();
        console.log(fetchedData)
        processFetchedData(fetchedData);
        if (values.length > 0) {
          setIsLoading(false);
        } else {
          console.error(
            "Received data is not in the expected format or is empty."
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    getDataByDateRange();
  }, [values, dateRange, period]);
  
  const processFetchedData = (fetchedData) => {
    const processedRows = [];
    const timestamps = new Set();
  
    // Zaman bilgilerini topla
    Object.keys(fetchedData).forEach((tableName) => {
      const tableData = fetchedData[tableName];
      Object.keys(tableData).forEach((columnName) => {
        const columnData = tableData[columnName];
        if (Array.isArray(columnData)) {
          columnData.forEach(([timestamp]) => timestamps.add(timestamp));
        }
      });
    });
  
    // Her zaman için bir satır oluştur
    Array.from(timestamps)
      .sort()
      .forEach((timestamp) => {
        const row = { zaman_etiketi: timestamp };
  
        // Verileri ilgili zaman dilimiyle eşleştir
        Object.keys(fetchedData).forEach((tableName) => {
          const tableData = fetchedData[tableName];
          Object.keys(tableData).forEach((columnName) => {
            const columnData = tableData[columnName];
            if (Array.isArray(columnData)) {
              const value = columnData.find(([ts]) => ts === timestamp)?.[1];
              row[columnName] = value !== undefined ? value : null;
            }
          });
        });
  
        processedRows.push(row);
      });
  
    setRows(processedRows);
  
    console.log("Processed Rows:", processedRows); // Kontrol etmek için
  };
  
  

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col overflow-auto w-full h-full gap-3">
     
     {/* Select boxes  */}
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around px-0.5  w-full gap-1 sm:gap-3 ">
        <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-40">
          <TableMultiSelectBox theme={theme} selectedColors={colors} />
        </div>
        <div className="flex flex-row xxs:flex-col gap-1 sm:gap-3 items-center">
          <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-30">
            <DatePicker />
          </div>
          <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-20  flex flex-row gap-1 sm:gap-3">
            <div className="flex-1 w-full h-10 z-30 3xl:!h-16 4xl:!h-20">
              <PeriodSelectBox />
            </div>
            <div
              className="relative flex h-10 3xl:!h-16 4xl:!h-20 z-1 text-text-color"
              ref={menuRef}
            >
              <div
                className="bg-sideheadbar-color flex items-center justify-center w-10 h-10 p-2 rounded-md border border-border-color cursor-pointer 3xl:!h-16 4xl:!h-20"
                onClick={handleMenuToggle}
              >
                <BsDownload size={16} className="text-logo-color" />
              </div>

              {isMenuOpen && (
                <div className="absolute w-24 mt-1 top-full right-0 bg-sideheadbar-color rounded-sm shadow-md border border-border-color">
                  {/* Buraya tıklandığında açılacak seçenekleri ekleyin */}
                  <div
                    onClick={() => downloadExcel(rows)}
                    className="py-2 px-4 rounded-sm cursor-pointer hover:bg-component-color"
                  >
                    Excel
                  </div>
                  <div
                    onClick={() => downloadAsCsv(rows)}
                    className="py-2 px-4 rounded-sm cursor-pointer  hover:bg-component-color"
                  >
                    Csv
                  </div>

                  {/* İstediğiniz kadar seçenek ekleyebilirsiniz */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading && values.length>0 ? (
        <div class="flex items-center space-x-2 self-center justify-center flex-1 ">
          <div aria-label="Loading..." role="status">
            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              xmlns="http://www.w3.org/2000/svg"
              class="animate-spin w-8 h-8 stroke-slate-500"
            >
              <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
            </svg>
          </div>
          <span class="text-lg font-medium text-slate-500">Loading...</span>
        </div>
      ) : (
        values.length>0 &&
        dateRange &&
        period &&
        !isErrror && (
          <div className="bg-sideheadbar-color flex flex-1 overflow-hidden !rounded-md">
            <CustomTable values={headCells} rows={rows} />
          </div>
        )
      )}

      {(!period || !dateRange || values.length===0 ) && (
        <div className="flex flex-1 items-center justify-center text-gray-500">
         {t('table.table-desc')}
        </div>
      )}
      
    </div>
  );
};

export default Table;
